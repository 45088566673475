import { Attendance } from "store/types"

const initialState = {
  attendees: {
    count: 0,
    list: [],
    page: 0
  },
  attendanceHistory: {
    count: 0,
    list: [],
    page: 0
  },
  attendance: {},
  attendanceResult: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Attendance.SET_ATTENDEES: {
      const { list, count, page, reset } = action.payload;
      return {
        ...state,
        attendees: {
          count,
          page,
          list: state.attendees.list.length && !reset
            ? [...state.attendees.list, ...list]
            : list,
        },
      };
    }
    case Attendance.SET_ATTENDANCE_HISTORY: {
      const { list, count, page, reset } = action.payload;
      return {
        ...state,
        attendanceHistory: {
          count,
          page,
          list:
            state.attendanceHistory.list.length && !reset
              ? [...state.attendanceHistory.list, ...list]
              : list,
        },
      };
    }
    case Attendance.REMOVE_ATTENDANCE: {
      const { id } = action.payload
      return {
        ...state,
        attendanceHistory: {
          ...state.attendanceHistory,
          count: state.attendanceHistory.count - 1,
          list: state.attendanceHistory.list.filter((data) => data.id !== id),
        },
      };
    }
    case Attendance.MARK_ATTENDANCE: {
      const { userId, result } = action.payload
      return {
        ...state,
        attendanceResult: {
          ...state.attendanceResult,
          [userId]: result
        }
      }
    }
    case Attendance.SET_ATTENDANCE_RESULT: {
      const { result } = action.payload
      return {
        ...state,
        attendanceResult: result
      }
    }
    case Attendance.RESET_MARKED_ATTENDANCE: {
      return {
        ...state,
        attendanceResult: {}
      }
    }
    case Attendance.RESET_ATTENDANCE_HISTORY: {
      return {
        ...state,
        attendanceHistory: initialState.attendanceHistory
      }
    }
    case Attendance.SET_ATTENDANCE: {
      return {
        ...state,
        attendance: action.payload
      }
    }
    default: {
      return state;
    }
  }
}

export default reducer
