import { Event } from "store/types";

const initialState = {
  events: {
    list: [],
    count: 0,
    page: 0
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case Event.SET_EVENTS: {
      const { list, count, page, reset } = action.payload;
      return {
        ...state,
        events: {
          count,
          page,
          list: state.events.list.length && !reset
            ? [...state.events.list, ...list]
            : list,
        },
      };
    }
    case Event.ADD_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          list: [action.payload, ...state.events.list]
        }
      }
    }
    case Event.EDIT_EVENT: {
      const { eventId, eventInfo } = action.payload
      return {
        ...state,
        events: {
          ...state.events,
          list: state.events.list.map(event => {
            if (event.id === eventId) {
              return eventInfo
            }
            return event
          })
        }
      }
    }
    case Event.REMOVE_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          count: state.events.count - 1,
          list: state.events.list.filter(
            (event) => event.id !== action.payload.eventId
          ),
        },
      };
    }
    case Event.RESET_EVENTS: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
