import { Admin } from "store/types"


const initialState = {
  roles: []
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case Admin.SET_ROLES: {
      return {
        ...state,
        roles: action.payload.roles,
      };
    }
    case Admin.REMOVE_ROLE: {
      const { id } = action.payload
      return {
        ...state,
        roles: state.roles.filter(role => role.id !== id)
      }
    }
    case Admin.UPDATE_ROLE: {
      const { id, update } = action.payload
      return {
        ...state,
        roles: state.roles.map((role) => {
          if (role.id === id) {
            return {
              ...role,
              ...update,
            };
          }
          return role;
        }),
      };
    }
    default:
      return state
  }
}

export default reducer
