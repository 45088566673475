import { NavLink } from "react-router-dom";
import { routes } from "router/routes";

const Page404 = () => {
  return (
    <section className="bg-white h-screen">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="text-secondary mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-700">
            Sorry, we can't find that page. You'll find lots to explore on the
            home page.{" "}
          </p>
          <NavLink
            to={routes.home.path}
            className="inline-flex text-white bg-secondary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
          >
            Back to Homepage
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default Page404