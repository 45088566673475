import React from "react";
import { FormProvider } from 'react-hook-form'

const Form = ({ children, onSubmit, form, className, autoComplete }) => {
  if (!form) {
    throw new Error('Form prop is required.')
  }
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={className}
        autoComplete={autoComplete}
      >
        {children}
      </form>
    </FormProvider>
  );
}

export default Form
