import {
  signInWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  getAuth,
  sendPasswordResetEmail
} from "firebase/auth";
import {
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { firebaseAuth, firestoreDb, storage } from "helpers/firebase";
import { Auth } from "store/types";
import { get, put, post } from "api";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    const data = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    if (!data.user.emailVerified) {
      const auth = getAuth()
      await sendEmailVerification(auth.currentUser);
      await firebaseAuth.signOut()
      const error = new Error('Email not verified')
      error.code = 'auth/email-not-verified'
      throw error
    }
    await getUserInfo({
      uid: data.user.uid,
      emailVerifed: data.user.emailVerified
    })(dispatch);
  };

export const getUserInfo =
  ({ uid, emailVerified = false }) =>
  async (dispatch) => {
    try {
      const docRef = doc(firestoreDb, "users", uid);
      await updateDoc(docRef, {
        emailVerified
      })
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error("User does not exist.");
      }

      const user = docSnap.data();
      // get user role
      const roleRef = collection(firestoreDb, "roles");
      const roleQuery = query(
        roleRef,
        where("tenant", "==", user.tenant)
      );
      const roleSnap = await getDocs(roleQuery);
      let roleDict = {}
      let roles = roleSnap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      roles.forEach(role => roleDict[role.roleKey] = role)

      if (!roleDict[user.role]) {
        // redirect to Error 500 page, user does not have verifiable role
        throw new Error("User role does not exist. " + user.role);
      }

      dispatch({
        type: Auth.SET_USER_ROLES,
        payload: roleDict
      })

      return dispatch({
        type: Auth.SET_USER,
        payload: {
          user: {
            ...user,
            uid,
            emailVerified,
            role: roleDict[user.role],
          },
        },
      });
    } catch (error) {
      ;
    }
  };

export const logout = () => async (dispatch) => {
  await firebaseAuth.signOut();
  dispatch({
    type: Auth.LOGOUT,
  });
};

export const getTenant = () => async (dispatch, getState) => {
  const { hostname } = window.location;
  const tenantRef = collection(firestoreDb, "tenants");
  const tenantQuery = query(
    tenantRef,
    where("domainWhitelist", "array-contains", hostname)
  );
  const result = await getDocs(tenantQuery);
  let tenant = result.docs[0];
  if (!tenant) {
    const error = new Error("Tenant does not exist.");
    error.code = "tenant/not-found";
    // todo catch the error and navigate user to 404 page
    throw error;
  }
  tenant = tenant.data();
  await getSiteSettings(tenant.tenantSlug)(dispatch)
  dispatch({
    type: Auth.SET_TENANT,
    payload: tenant.tenantSlug,
  });
};

export const getSiteSettings = (tenant) => async (dispatch) => {
  const siteSettingRef = doc(firestoreDb, "site-settings", tenant);
  const siteSettingSnap = await getDoc(siteSettingRef);

  const locationRef = collection(firestoreDb, "site-settings", tenant, "locations")
  const locationQuery = query(locationRef)
  const locations = (await getDocs(locationQuery)).docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  dispatch({
    type: Auth.SET_SITE_SETTINGS,
    payload: {
      ...siteSettingSnap.data(),
      locations
    },
  });
}

export const getMemberProfile = (uid) => async (dispatch) => {
  const { data } = await get({
    subUrl: `/member/detail/${uid}`,
  });
  dispatch({
    type: Auth.SET_USER_FULL_DATA,
    payload: data,
  });
  return data;
};

export const getParentProfile = (uid) => async (dispatch) => {
  const { data } = await get({
    subUrl: `/parent/detail/${uid}`,
  });
  dispatch({
    type: Auth.SET_USER_FULL_DATA,
    payload: data,
  });
  return data;
};

export const getMenteeProfile = (uid) => async (dispatch) => {
  const { data } = await get({
    subUrl: `/mentee/detail/${uid}`,
  });
  dispatch({
    type: Auth.SET_USER_FULL_DATA,
    payload: data,
  });
  return data;
};

export const saveMemberProfile =
  ({ uid, data }) =>
  async (dispatch) => {
    const docRef = doc(firestoreDb, "users", uid);
    await updateDoc(docRef, {
      firstName: data.memberfname,
      lastName: data.memberlname,
      location: data.mentoringlocation,
    });
    await put({
      subUrl: `/member/detail/${uid}`,
      data,
    });
    return;
  };

export const saveParentProfile =
  ({ uid, data }) =>
  async (dispatch) => {
    const docRef = doc(firestoreDb, "users", uid);
    await updateDoc(docRef, {
      firstName: data.parentfname,
      lastName: data.parentlname,
      location: data.mentoringlocation,
    });
    await put({
      subUrl: `/parent/detail/${uid}`,
      data,
    });
    return;
  };

export const saveMenteeProfile =
  ({ uid, data }) =>
  async (dispatch) => {
    const docRef = doc(firestoreDb, "users", uid);
    await updateDoc(docRef, {
      firstName: data.menteefname,
      lastName: data.menteelname,
      location: data.menteelocation,
    });
    await put({
      subUrl: `/mentee/detail/${uid}`,
      data,
    });
    return;
  };

export const changePassword =
  ({ email, oldPassword, newPassword }) =>
  async (dispatch) => {
    const user = firebaseAuth.currentUser;
    const cred = EmailAuthProvider.credential(email, oldPassword);
    await reauthenticateWithCredential(user, cred);
    await updatePassword(user, newPassword);
    const newCred = EmailAuthProvider.credential(email, newPassword);
    await reauthenticateWithCredential(user, newCred);
  };

export const updateUserEmail = ({ newEmail, userType }) => async (dispatch, getState) => {
  const { auth: { user } } = getState()
  const currentUser = firebaseAuth.currentUser
  const userRef = doc(firestoreDb, "users", currentUser.uid)
  await updateEmail(currentUser, newEmail)
  await updateDoc(userRef, {
    email: newEmail
  })
  await put({
    subUrl: `/${userType}/detail/${currentUser.uid}`,
    data: {
      [`${userType}email`]: newEmail,
    },
  });

  return dispatch({
    type: Auth.SET_USER,
    payload: {
      user: {
        ...user,
        email: newEmail,
      },
    },
  });
}

const defaultRoleTypes = {
  'member': 'member',
  'guest': 'member',
  'parent': 'parent',
  'emerging-100': 'member',
  'collegiate-100': 'member'
}

export const signup = (data) => async (dispatch, getState) => {
  const { auth: { tenant, siteSettings } } = getState()
  const userInfo = {
    ...data,
    tenant,
    approveduser: false
  }
  delete userInfo.password
  delete userInfo.retypePassword

  const createdUser = await createUserWithEmailAndPassword(
    firebaseAuth,
    data.email,
    data.password
  );
  userInfo.createdDate = (new Date(createdUser.user.metadata.creationTime)).getTime()
  userInfo.lastLogin = (new Date(createdUser.user.metadata.lastSignInTime)).getTime()
  
  const docRef = doc(firestoreDb, "users", createdUser.user.uid)
  
  await setDoc(docRef, userInfo)
  const { firstName, lastName, email, role, location } = userInfo
  await post({
    subUrl: `/${defaultRoleTypes[userInfo.role]}`,
    data: {
      firstName,
      lastName,
      role,
      location,
      email,
      uid: createdUser.user.uid,
    },
  });
  // TODO: Move this to the server
  const settings = {
    templateId:
      defaultRoleTypes[userInfo.role] === "member"
        ? "d-e617f81024444da99c28292fb63ba5d3"
        : "d-f878aa04eae241f4a3ee0a86abad5040",
    from: {
      email: "no-reply@mentor100.thesimplevue.com",
      name: "Mentor100 Portal Communications",
    },
    personalizations: [
      {
        to: {
          email,
          name: `${firstName} ${lastName}`,
        },
        dynamic_template_data: {
          signInLink: `${window.location.origin}/login`,
          mentoringLocation: siteSettings.locations.find(
            (loc) => loc.id === location
          )?.name,
          name: `${firstName} ${lastName}`,
          main_site_name: siteSettings.siteName,
          site_contact_email: siteSettings.contactEmail,
          site_email_logo:
            siteSettings.emailLogo || `${window.location.origin}/img/logo.png`,
        },
      },
    ],
  };
  await post({
    subUrl: '/action/send-email',
    data: {
      settings
    },
    headers: {
      Authorization: `Bearer ${await createdUser.user.getIdToken()}`
    }
  })
  await sendEmailVerification(createdUser.user);
};

export const updateAvatar = ({ file, uid }) => async (dispatch, getState) => {
  const { auth: { user } } = getState()
  const userRef = doc(firestoreDb, "users", uid)
  const imageRef = ref(storage, `users/${uid}`)
  await uploadBytes(imageRef, file)
  const imageUrl = await getDownloadURL(imageRef)

  // update user info
  await updateDoc(userRef, {
    avatar: imageUrl
  })

  dispatch({
    type: Auth.SET_USER,
    payload: {
      user: {
        ...user,
        avatar: imageUrl,
      },
    },
  });
}

export const resetPassword = ({ email }) => {
  return sendPasswordResetEmail(firebaseAuth, email)
}

export const acceptTerms = () => async (dispatch, getState) => {
  const { auth: { user } } = getState()
  const userRef = doc(firestoreDb, "users", user.uid);

  await updateDoc(userRef, {
    agreedToRules: true
  })

  dispatch({
    type: Auth.SET_USER,
    payload: {
      user: {
        ...user,
        agreedToRules: true,
      },
    },
  });
}
