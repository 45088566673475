import axios from 'axios'
import { firebaseAuth } from '../helpers/firebase'

const baseUrl = process.env.REACT_APP_API_URL
const codes = {
  UNAUTHORIZED: [401],
  CUSTOM_TOKEN_EXPIRED: -2,
  REQUEST_TIMEOUT: 408,
  ECONNABORTED: 'ECONNABORTED',
}

const getNewToken = async () => {
  const token = await firebaseAuth?.currentUser?.getIdToken()
  return token
}
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (codes.UNAUTHORIZED.includes(error?.response?.status)) {
      firebaseAuth.signOut()
    }

    if (
      error?.response?.status === codes.REQUEST_TIMEOUT ||
      error.code === codes.ECONNABORTED
    ) {
      //Looks like the server is taking to long to respond, please try again in sometime.
      console.log(`A timeout happend on url ${error.config.url}`)
      //errorToast({ content: 'Server request timed out. Please retry again.' })
    }

    return Promise.reject(error)
  }
)

export const getFullUrl = (url) => {
  return `${baseUrl}${url}`
}

/**
 * @typedef {{ subUrl: string;  method?: Method;  data?: object;  params?: object;  headers?: object; cancelToken?:any}} Irequest
 */

/**
 * @param {Irequest} request
 */
export const get = (request) => {
  return commonFetch({ method: 'get', ...request })
}

/**
 * @param {Irequest} request
 */
export const post = (request) => {
  return commonFetch({ method: 'post', ...request })
}

/**
 * @param {Irequest} request
 */
export const patch = (request) => {
  return commonFetch({ method: 'patch', ...request })
}

/**
 * @param {Irequest} request
 */
export const put = (request) => {
  return commonFetch({ method: 'put', ...request })
}

/**
 * @param {Irequest} request
 */
export const deletee = (request) => {
  return commonFetch({ method: 'delete', ...request })
}
/**
 * @param {Irequest} request
 */
const commonFetch = async (request) => {
  const {
    subUrl,
    method,
    url,
    data = {},
    params,
    headers = {},
    cancelToken,
  } = request
  const commonHeaders = await getCommonHeaders()
  return axios({
    method,
    url: url || getFullUrl(subUrl),
    params,
    data,
    headers: { ...commonHeaders, ...headers },
    cancelToken,
  })
}

export const content_types = {
  multipart: {
    'Content-Type': 'multipart/form-data',
  },
  json: {
    'Content-Type': 'application/json',
  },
}

const getCommonHeaders = async () => {
  // const { auth } = store.getState()
  const token = await getNewToken()
  return {
    ...content_types.json,
    Authorization: token ? `Bearer ${token}` : null,
    token_type: 'id',
    tenant: 'north-metro-100bm'
  }
}

export const convertToForm = (item) => {
  const form_data = new FormData()
  for (const key in item) {
    const value = item[key]
    if (value) {
      form_data.append(key, value)
    }
  }
  return form_data
}

export const getErrorMessage = (error) => {
  if (typeof error?.response?.data.error === 'string') {
    return error?.response?.data.error
  } else {
    return ''
  }
}

export const cancellableApiRequest = () => {
  let cancelToken = null
  const apiCall = async (fn, config) => {
    if (cancelToken) {
      cancelToken.cancel()
    }
    cancelToken = axios.CancelToken.source()
    const data = await fn({ ...config, cancelToken: cancelToken.token })
    cancelToken = null
    return data
  }
  return apiCall
}