import { FileManager } from "store/types";

const initialState = {
  currentPath: {
    pathStructure: [
      {
        name: "Root",
        path: "/root",
        slug: "root",
      },
    ],
    rows: [],
    path: "root",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case FileManager.ADD: {
    //   return {
    //     ...state,
    //     currentPath: {
    //       ...state.currentPath,
    //       rows: [action.payload, ...state.currentPath.rows],
    //     },
    //   };
    // }
    case FileManager.SET_FILES_AND_FOLDERS: {
      return {
        ...state,
        currentPath: action.payload,
      };
    }
    case FileManager.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
