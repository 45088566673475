import { deletee } from "api"
import { Admin } from "store/types"

const { collection, addDoc, doc, updateDoc, query, where, getDocs } = require("firebase/firestore")
const { firestoreDb } = require("helpers/firebase")


export const addRole = ({ role }) => async (dispatch, getState) => {
  const { auth: { tenant }, admin: { roles } } = getState()
  const roleRef = collection(firestoreDb, "roles")
  const roleSnap = await getDocs(roleRef)
  const allRoles = roleSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }))

  // check if there is an existing role with the role name
  const foundRole = allRoles.find(r => r.name === role.name)

  if (foundRole) {
    const error = new Error('A role already exists with the given role name. Try a different name')
    error.code = 'existing-role'
    throw error
  }

  const doc = await addDoc(roleRef, {
    ...role,
    tenant
  })
  return dispatch({
    type: Admin.SET_ROLES,
    payload: {
      roles: [{ id: doc.id, ...role, tenant }, ...roles],
    },
  });
}

export const updateRole = ({ role, roleId }) => async (dispatch, getState) => {
  const { auth: { tenant } } = getState()
  const roleRef = doc(firestoreDb, "roles", roleId)
  delete role.id
  await updateDoc(roleRef, {
    ...role,
    tenant
  })

  return dispatch({
    type: Admin.UPDATE_ROLE,
    payload: {
      id: roleId,
      update: {
        ...role,
        tenant,
      },
    },
  });
}

export const getRoles = () => async (dispatch, getState) => {
  const {
    auth: { tenant },
  } = getState();
  const roleRef = collection(firestoreDb, "roles");
  const roleQuery = query(roleRef, where("tenant", "==", tenant));
  const roleSnap = await getDocs(roleQuery);
  const roles = roleSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  dispatch({
    type: Admin.SET_ROLES,
    payload: {
      roles,
    },
  });

  return roles
}

export const deleteRole = (id) => async (dispatch) => {
  await deletee({
    subUrl: `/admin/delete-role/${id}`
  })

  dispatch({
    type: Admin.REMOVE_ROLE,
    payload: {
      id
    }
  })
}