import { Auth } from "../types";

const initialState = {
  user: null,
  loading: {},
  tenant: null,
  userFullData: null,
  siteSettings: null,
  userRoles: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Auth.SET_USER: {
      return { ...state, user: action.payload.user };
    }
    case Auth.LOGOUT: {
      return initialState;
    }
    case Auth.SET_TENANT: {
      return {
        ...state,
        tenant: action.payload,
      };
    }
    case Auth.SET_SITE_SETTINGS: {
      return {
        ...state,
        siteSettings: action.payload,
      };
    }
    case Auth.SET_USER_FULL_DATA: {
      return {
        ...state,
        userFullData: action.payload,
      };
    }
    case Auth.SET_USER_ROLES: {
      return {
        ...state,
        userRoles: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
