export const Auth = {
  SET_USER: '[Auth] SET USERS',
  LOGOUT: '[Auth] LOG OUT',
  SET_TENANT: '[Auth] SET TENANT',
  SET_SITE_SETTINGS: '[Auth] SET SITE SETTINGS',
  SET_USER_FULL_DATA: '[Auth] SET USER FULL DATA',
  SET_USER_ROLES: '[Auth] SET USER ROLES'
}

export const Post = {
  SET_POSTS: '[Post] SET POSTS',
  SET_COMMENTS: '[Post] SET_COMMENTS',
  RESET_POSTS: '[Post] RESET POSTS',
  SET_LAST_POST: '[Post] SET LAST POST',
  SET_LAST_COMMENT: '[Post] SET LAST COMMENT',
  TOGGLE_POST_LIKE: '[Post] TOGGLE POST LIKE',
  UPDATE_POST: '[Post] UPDATE POST',
  REMOVE_POST: '[Post] REMOVE POST'
}

export const Event = {
  SET_EVENTS: '[Event] SET EVENTS',
  ADD_EVENT: '[Event] ADD EVENT',
  REMOVE_EVENT: '[Event] REMOVE EVENT',
  EDIT_EVENT: '[Event] EDIT EVENT',
  RESET_EVENTS: '[Event] RESET EVENTS'
}

export const Notification = {
  SET_NOTIFICATION: '[Notification] SET NOTIFICATION',
  RESET_NOTIFICATION: '[Notification] RESET NOTIFICATION'
}

export const Attendance = {
  SET_ATTENDEES: '[Attendance] SET ATTENDEES',
  MARK_ATTENDANCE: '[Attendance] MARK ATTENDANCE',
  SET_ATTENDANCE_RESULT: '[Attendance] SET ATTENDANCE RESULT',
  SET_ATTENDANCE: '[Attendance] SET ATTENDANCE',
  RESET_MARKED_ATTENDANCE: '[Attendance] RESET MARKED ATTENDANCE',
  SET_ATTENDANCE_HISTORY: '[Attendance] SET ATTENDANCE HISTORY',
  RESET_ATTENDANCE_HISTORY: '[Attendance] RESET ATTENDANCE HISTORY',
  REMOVE_ATTENDANCE: '[Attendance] REMOVE ATTENDANCE',
  ADD_ATTENDANCE: '[Attendance] ADD ATTENDANCE'
}

export const Directory = {
  SET_USERS: '[Directory] SET USERS',
  UPDATE_USER: '[Directory] UPDATE USER',
  REMOVE_USER: '[Directory] REMOVE USER',
  RESET: '[Directory] RESET'
}

export const Volunteer = {
  SET_VOLUNTEER_EVENTS: "[Volunteer] SET VOLUNTEER EVENTS",
  ADD_VOLUNTEER_EVENT: "[Volunteer] ADD VOLUNTEER EVENT",
  UPDATE_VOLUNTEER_EVENT: "[Volunteer] UPDATE VOLUNTEER EVENT",
  REMOVE_VOLUNTEER_EVENT: "[Volunteer] REMOVE VOLUNTEER EVENT",
  RESET_VOLUNTEER_EVENTS: "[Volunteer] RESET VOLUNTEER EVENTS",
};

export const Mentee = {
  SET_MENTEES: "[Mentee] SET MENTEES",
  SET_SELECTED_MENTEE: "[Mentee] SET SELECTED MENTEE",
  SET_SELECTED_MENTEE_APP: "[Mentee] SET SELECTED MENTEE APPLICATION",
  SET_NEW_PERF_DATA: "[Mentee] SET NEW PERFORMANCE TRACKING DATA",
  SET_REPORT_CARDS: "[Mentee] SET REPORT CARDS",
  SET_BEHAVIOR_SURVEYS: "[Mentee] SET BEHAVIOR SURVEYS",
  SET_HS_CREDITS: "[Mentee] SET HS CREDITS",
  RESET_PERF_DATA: "[Mentee] RESET PERFORMANCE DATA",
  UPDATE_REPORT_CARD: "[Mentee] UPDATE REPORT CARD",
  UPDATE_BEHAVIOR_SURVEY: "[Mentee] UPDATE BEHAVIOR SURVEY",
  UPDATE_HS_CREDIT: "[Mentee] UPDATE HS CREDIT",
  REMOVE_REPORT_CARD: "[Mentee] REMOVE REPORT CARD",
  REMOVE_BEHAVIOR_SURVEY: "[Mentee] REMOVE BEHAVIOR SURVEY",
  REMOVE_HS_CREDIT: "[Mentee] REMOVE HS CREDIT",
}

export const Admin = {
  SET_ROLES: "[Admin] SET ROLES",
  UPDATE_ROLE: "[Admin] UPDATE ROLE",
  REMOVE_ROLE: "[Admin] REMOVE ROLE"
}

export const FileManager = {
  ADD: "[File Manager] ADD",
  SET_FILES_AND_FOLDERS: "[File Manager] SET FILES AND FOLDERS",
  RESET: "[File Manager] RESET"
}