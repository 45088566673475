import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { connect, useDispatch, useStore } from "react-redux";
import pluralize from "pluralize";

import Button from "components/Button/Button";
import Form from "components/Form/Form";
import Modal from "components/Modal/Modal";
import { Notification } from "store/types";
import { sendNotification } from "store/actions/notification";
import { getRoles } from "store/actions/roleManagement";
import FormItemUncontrolled from "components/Form/FormItem/FormItemUncontrolled";

const NotifyUserModal = ({
  user,
  visible,
  notificationData,
  notificationKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const store = useStore();
  const form = useForm({
    defaultValues: {},
  });

  const allowAllRoles = useWatch({
    control: form.control,
    name: "allowAllRoles",
  });

  const onCancel = () => {
    if (loading) return;
    dispatch({
      type: Notification.RESET_NOTIFICATION
    });
    form.reset({
      roles: roles.map((role) => {
        return {
          roleKey: role.roleKey,
          send: false,
          name: role.name,
        };
      }),
    });
  };

  const onSubmit = async (val) => {
    try {
      setLoading(true);
      const rolesToReceive = !val.allowAllRoles
        ? val.roles
            .filter((role) => role.send)
            .map((role) => role.roleKey)
        : null;

      if (!val.allowAllRoles && !rolesToReceive?.length) {
        toast.error("Please select at least one role to receive notification.");
        return;
      }

      await sendNotification({
        key: notificationKey,
        roles: rolesToReceive,
        data: notificationData
      })(dispatch)

      toast.success("Notification sent successfully");
      onCancel()
    } catch (error) {
      toast.error(
        `An error occurred while sending the notification. Try again later.`
      );
    } finally {
      setLoading(false);
    }
  };

  const getAllRoles = useCallback(async () => {
    try {
      let roles = await getRoles()(dispatch, store.getState);
      roles = roles.map((role) => {
        return {
          roleKey: role.roleKey,
          name: role.name,
        };
      });
      setRoles(roles);
      form.reset({
        roles: roles.map((role) => {
          return {
            roleKey: role.roleKey,
            send: false,
            name: role.name,
          };
        }),
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (!user) return
    getAllRoles();
  }, [user, getAllRoles]);

  return (
    <Modal
      className="max-w-md"
      title="Send Notification"
      visible={visible}
      onCloseModal={onCancel}
    >
      <Form form={form} onSubmit={onSubmit}>
        <label className="mb-2 block mt-[14px]">Roles</label>
        <Controller
          name="allowAllRoles"
          render={({ field }) => {
            return (
              <div className="flex items-center mb-4">
                <div>
                  <div className="flex items-center">
                    <input
                      id="allowAllRoles"
                      type="checkbox"
                      className="h-[20px] w-[20px] mr-3 rounded-md"
                      {...field}
                      checked={field.value}
                    />
                    <label htmlFor="allowAllRoles" className="cursor-pointer">
                      All
                    </label>
                  </div>
                </div>
              </div>
            );
          }}
        />
        {!allowAllRoles && (
          <FormItemUncontrolled name="roles" list={true}>
            {({ fields, register }) => {
              return (
                <>
                  <div className="grid gap-3 grid-cols-1 sm:grid-cols-2">
                    {fields.map((field, index) => (
                      <div className="form-item mb-2" key={index}>
                        <label className="flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="h-[20px] !w-[20px] mr-3 rounded-md"
                            {...register(`roles.${index}.send`)}
                          />
                          <div className="flex-1 block w-full">
                            {field.name !== "All"
                              ? pluralize.plural(field.name)
                              : field.name}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              );
            }}
          </FormItemUncontrolled>
        )}
        <div className="flex mt-[25px]">
          <Button
            type="button"
            disabled={loading}
            onClick={onCancel}
            className="w-auto mr-3 bg-primary text-white !shadow-none border-none font-medium"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            className="bg-secondary border-none text-white !shadow-none font-medium"
          >
            Send
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ notification, auth }) => ({
  user: auth.user,
  visible: notification.notify.visible,
  notificationData: notification.notify.notificationData,
  notificationKey: notification.notify.notificationKey,
})

export default connect(mapStateToProps)(NotifyUserModal);
