import { Fragment } from 'react';
import { Menu, Transition, Popover } from '@headlessui/react';
import classNames from 'classnames';

const MenuDropdown = ({ className,  children, menuItems, dropdownBtnClass }) => {
  return (
    <Menu
      as="div"
      className="mentor100-dropdown relative text-left flex items-center"
    >
      <Menu.Button onClick={(e) => e.stopPropagation()} as='div' className={
        classNames('w-full flex justify-center', {
          [dropdownBtnClass]: dropdownBtnClass
        })
      }>
        {
          children
        }
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="section"
          className={
            classNames("origin-top-right z-[150] absolute right-0 top-[45px] mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none", {
              [className]: className
            })
          }
        >
          {menuItems}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const PopoverDropdown = ({ children, popoverPanel, className, dropdownBtnClass }) => {
  return (
    <Popover as="div" className="relative text-left flex items-center">
      <Popover.Button
        onClick={(e) => e.stopPropagation()}
        as="div"
        className={classNames("w-full", {
          [dropdownBtnClass]: dropdownBtnClass,
        })}
      >
        {children}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel
          as="section"
          className={classNames(
            "origin-top-right z-[150] absolute right-0 top-[45px] mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
            {
              [className]: className,
            }
          )}
        >
          {popoverPanel}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

const Dropdown = ({ children, menuItems, dropdownBtnClass, popoverPanel, className, type = 'menu' }) => {
  if (type === 'menu') {
    return (
      <MenuDropdown menuItems={menuItems} className={className} dropdownBtnClass={dropdownBtnClass}>
        {children}
      </MenuDropdown>
    );
  }
  if (type === 'popover') {
    return (
      <PopoverDropdown popoverPanel={popoverPanel} className={className} dropdownBtnClass={dropdownBtnClass}>
        {children}
      </PopoverDropdown>
    );
  } 
}

export default Dropdown
