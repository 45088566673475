import classNames from "classnames";
import React from "react"
import { useFormContext, useFieldArray, useController } from 'react-hook-form'

import './FormItem.css'

const FormItemArray = ({ name, control, register, children }) => {
  const fieldArrayData = useFieldArray({
    name,
    control
    // keyName: "id", default to "id", you can change the key name
  });

  return children({
    ...fieldArrayData,
    register
  })
}

const FormItemSingle = (props) => {
  const { name, control, rules, register, children } = props
  const { field } = useController({
    name,
    control,
    rules
  })
  return children({
    field,
    control,
    name,
    register
  })
}

const FormItemUncontrolled = (props) => {
  const { label, name, list = false, className, disableDarkMode } = props
  const { register, control } = useFormContext()

  return (
    <div
      className={classNames({
        "form-item mb-2": true,
        "disable-dark-mode": disableDarkMode,
        [className]: !!className,
      })}
    >
      {label && (
        <label htmlFor={name} className="mb-2 block">
          {label}
        </label>
      )}
      {list ? (
        <FormItemArray {...props} register={register} control={control} />
      ) : (
        <FormItemSingle {...props} register={register} control={control} />
      )}
    </div>
  );
};

export default FormItemUncontrolled;
