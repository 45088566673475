import { Notification } from "store/types";

const initialState = {
  notify: {
    visible: false,
    notificationKey: null,
    notificationData: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Notification.SET_NOTIFICATION: {
      return {
        ...state,
        notify: {
          ...state.notify,
          ...action.payload,
        },
      };
    }
    case Notification.RESET_NOTIFICATION: {
      return {
        ...state,
        notify: {
          ...initialState.notify,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer
