import { Directory } from "store/types";

const initialState = {
  users: {
    list: [],
    page: 0,
    count: 0
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Directory.SET_USERS: {
      const { list, count, page, reset } = action.payload;
      return {
        ...state,
        users: {
          count,
          page,
          list:
            state.users.list.length && !reset
              ? [...state.users.list, ...list]
              : list,
        },
      };
    }
    case Directory.UPDATE_USER: {
      const { id, update } = action.payload
      return {
        ...state,
        users: {
          ...state.users,
          list: state.users.list.map((user) => {
            if (user.id === id) {
              return {
                ...user,
                ...update,
              };
            }
            return user;
          }),
        },
      };
    }
    case Directory.REMOVE_USER: {
      const { id } = action.payload
      return {
        ...state,
        users: {
          ...state.users,
          list: state.users.list.filter(user => user.id !== id)
        }
      }
    }
    case Directory.RESET: {
      return initialState
    }
    default:
      return state;
  }
};

export default reducer