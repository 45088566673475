import classNames from "classnames"
import React from "react";
import Loader from "../Loader/Loader";
import './Button.css'

const Button = ({ as = 'button', children, className, loading, ...otherProps }) => {
  const element = React.createElement(as)
  return (
    <element.type
      className={classNames("btn", {
        [className]: className,
        '!inline-flex items-center justify-center': loading
      })}
      {...otherProps}
    >
      {loading ? (
        <Loader wrapperClass='!p-[2px]' className='w-6 h-6 !text-white !mr-0'/>
      ) : (
        children
      )}
    </element.type>
  );
};

export default Button