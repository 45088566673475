import { Suspense } from 'react'
import { Provider } from 'react-redux';
import store from './store';
import AppRouter from './router'
import Loader from 'components/Loader/Loader';

function App() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <Loader
            className="center !text-secondary"
            size="large"
            wrapperClass="fullpage-loader"
          />
        }
      >
        <AppRouter />
      </Suspense>
    </Provider>
  );
}

export default App;
