import { Post } from "store/types";
const initialState = {
  posts: [],
  comments: {},
  lastPost: null,
  lastComment: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Post.SET_POSTS: {
      const { posts, reset } = action.payload
      return {
        ...state,
        posts: !state.posts.length || reset
          ? posts
          : [...state.posts, ...posts],
      };
    }
    case Post.SET_COMMENTS: {
      const { postId, comments, reset } = action.payload
      return {
        ...state,
        comments: {
          ...state.comments,
          [postId]: !state.comments[postId] || reset
            ? comments
            : [...state.comments[postId], ...comments],
        },
      };
    }
    case Post.SET_LAST_POST: {
      return {
        ...state,
        lastPost: action.payload
      }
    }
    case Post.SET_LAST_COMMENT: {
      const { postId, value } = action.payload
      return {
        ...state,
        lastComment: {
          ...state.lastComment,
          [postId]: value
        }
      }
    }
    case Post.RESET_POSTS: {
      return {
        ...state,
        posts: [],
      };
    }
    case Post.TOGGLE_POST_LIKE: {
      const { postId, type } = action.payload
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id !== postId) return post
          return {
            ...post,
            isLiked: type === 'like' ? true : false,
            likeCount: type === 'like' ? post.likeCount + 1 : post.likeCount - 1
          }
        })
      } 
    }
    case Post.UPDATE_POST: {
      const { postId, update } = action.payload
      return {
        ...state,
        posts: state.posts.map(post => {
          if (post.id === postId) {
            return {
              ...post,
              ...update
            }
          }
          return post
        })
      }
    }
    case Post.REMOVE_POST: {
      const { postId } = action.payload
      return {
        ...state,
        posts: state.posts.filter((post) => post.id !== postId),
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer