import { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth';
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'components/Loader/Loader'
import { firebaseAuth } from 'helpers/firebase'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Header from 'components/Header/Header'
import Page404 from 'pages/404/404';
import { getUserInfo, getTenant } from 'store/actions/auth';
import { routes, history } from './routes'
import NotifyUserModal from 'components/NotifyUserModal/NotifyUserModal';

const PageLayout = ({ Component, metaProps }) => {
  return (
    <>
      { metaProps.isPrivate && !metaProps.noHeader && <Header/> }
      {
        metaProps.isAuth ? (
          <main className='px-[10px] sm:px-[50px] pt-[50px] md:pt-[140px] pb-[50px]'>
            <Component/>
          </main>
        ) : (
          <main className='container mx-auto px-[10px] md:px-[20px] py-[30px]'>
            <Component />
          </main>
        )
      }
      <NotifyUserModal/>
      <ToastContainer/>
    </>
  );
}

const AppRouter = ({ user, getUserInfo, getTenant }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (data) => {
      try {
        await getTenant()
        if (!data) {
          setLoading(false)
          return;
        }
        if (history.location.pathname.includes('/signup')) {
          setLoading(false);
          return
        }
        if ((!user || user.id === "anonymous") && data) {
          const jsonUser = data.toJSON();
          if (!data.emailVerified) {
            setLoading(false)
            return firebaseAuth.signOut()
          }
          await getUserInfo({
            ...jsonUser.stsTokenManager,
            uid: data.uid,
            emailVerified: data.emailVerified,
          });
        }
        setLoading(false);
      } catch (error) {
        console.debug('ERROR WITH GETTING TENANT ', error)
        if (error.code === 'tenant/not-found') {
          setLoading(false)
          return history.push('/404')
        }
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <>
      {loading && (
        <Loader
          className="center !text-secondary w-[40px] h-[40px]"
          wrapperClass="fullpage-loader"
        />
      )}
      {!loading && (
        <Router>
          <Routes>
            {Object.keys(routes).map((key) => {
              const {
                component: Component,
                metaProps,
                ...otherRouteProps
              } = routes[key];
              const SelectedRoute = metaProps.isPrivate
                ? PrivateRoute
                : PublicRoute;
              return (
                <Route
                  {...otherRouteProps}
                  key={key}
                  element={
                    <SelectedRoute metaProps={metaProps}>
                      <PageLayout Component={Component} metaProps={metaProps} />
                    </SelectedRoute>
                  }
                />
              );
            })}
            <Route path="*" element={<Page404/>} />
          </Routes>
        </Router>
      )}
    </>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user
  }
}

export default connect(mapStateToProps, {
  getUserInfo,
  getTenant
})(AppRouter)
