import { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames';

const Modal = ({ onCloseModal, visible, title, children, unmount, className, withoutHeader = false }) => {
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        unmount={unmount}
        as="div"
        className="relative z-[1100]"
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[0.65]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "z-[1200] w-full max-w-lg transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all",
                  {
                    [className]: className,
                  }
                )}
              >
                {!withoutHeader && (
                  <Dialog.Title
                    as="h3"
                    className="mb-[20px] text-lg leading-6 text-gray-900 flex justify-between items-center"
                  >
                    <span className="w-full text-2xl font-bold">{title}</span>
                    <button
                      type=""
                      onClick={onCloseModal}
                      className="rounded-full hover:bg-opacity-10 bg-op bg-opacity-0 bg-black p-[7px] outline-none"
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </Dialog.Title>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal