import { connect } from "react-redux";
import { Navigate } from "react-router";
import { routes } from "./routes";

const PublicRoute = ({ children, metaProps, auth }) => {
  if (metaProps.isAuth && auth.user) {
    return (
      <Navigate to={routes.home.path}/>
    )
  }
  return <div>{children}</div>;
};

export default connect(({ auth }) => ({ auth }))(PublicRoute)
