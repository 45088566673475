import { createBrowserHistory } from "history";
import React from "react";
export const history = createBrowserHistory();

export const routes = {
  login: {
    id: "login",
    name: "Login",
    description: "Login",
    path: "/login",
    pathString: () => {
      return "/login";
    },
    exact: true,
    metaProps: {
      isPrivate: false,
      isAuth: true,
    },
    component: React.lazy(() => import("../pages/Auth/Login/Login")),
  },
  signup: {
    id: "signup",
    name: "Signup",
    description: "Signup",
    path: "/signup",
    pathString: () => {
      return "/signup";
    },
    exact: true,
    metaProps: {
      isPrivate: false,
      isAuth: true,
    },
    component: React.lazy(() => import("../pages/Auth/Signup/Signup")),
  },
  forgotPassword: {
    id: "forgotPassword",
    name: "Forgot Password",
    description: "Forgot Password",
    path: "/forgot-password",
    pathString: () => {
      return "/forgot-password";
    },
    exact: true,
    metaProps: {
      isPrivate: false,
      isAuth: true,
    },
    component: React.lazy(() =>
      import("../pages/Auth/ForgotPassword/ForgotPassword")
    ),
  },
  restrictedPage: {
    id: "restrictedPage",
    name: "Restricted Page",
    description: "Restricted Page",
    path: "/restricted",
    pathString: () => {
      return "/restricted";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: false
    },
    component: React.lazy(() => import("../pages/RestrictedPage/RestrictedPage")),
  },
  welcomePage: {
    id: "welcomePage",
    name: "Welcome Page",
    description: "Welcome Page",
    path: "/welcome",
    pathString: () => {
      return "/welcome";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      noHeader: true,
      isWelcome: true,
      onlyApprovedUser: false
    },
    component: React.lazy(() => import("../pages/WelcomePage/WelcomePage")),
  },
  home: {
    id: "home",
    name: "Home Page",
    description: "Home Page",
    path: "/",
    pathString: () => {
      return "/";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
    },
    component: React.lazy(() => import("../pages/Home/Home")),
  },
  myProfile: {
    id: "myProfile",
    name: "My Profile",
    description: "My Profile",
    path: "/profile",
    pathString: () => {
      return "/profile";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
    },
    component: React.lazy(() => import("../pages/MyProfile/MyProfile")),
  },
  events: {
    id: "events",
    name: "Events",
    description: "Events",
    path: "/events",
    pathString: () => {
      return "/events";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "events"
    },
    component: React.lazy(() => import("../pages/Events/Events")),
  },
  directory: {
    id: "directory",
    name: "Directory",
    description: "Directory",
    path: "/directory",
    pathString: () => {
      return "/directory";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "directory"
    },
    component: React.lazy(() => import("../pages/Directory/Directory")),
  },
  fileManager: {
    id: "fileManager",
    name: "Chapter Downloads",
    description: "Chapter Downloads",
    path: "/chapter-downloads",
    pathString: () => {
      return "/chapter-downloads";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "fileManager"
    },
    component: React.lazy(() => import("../pages/FileManager/FileManager")),
  },
  attendanceOverview: {
    id: "attendanceOverview",
    name: "Attendance Overview",
    description: "Attendance Overview",
    path: "/attendance",
    pathString: () => {
      return "/attendance";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "attendance"
    },
    component: React.lazy(() =>
      import("../pages/Attendance/AttendanceOverview/AttendanceOverview")
    ),
  },
  newAttendance: {
    id: "newAttendance",
    name: "New Attendance",
    description: "New Attendance",
    path: "/attendance/new",
    pathString: () => {
      return "/attendance/new";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "attendance"
    },
    component: React.lazy(() =>
      import("../pages/Attendance/NewAttendance/NewAttendance")
    ),
  },
  editAttendance: {
    id: "editAttendance",
    name: "Edit Attendance",
    description: "Edit Attendance",
    path: "/attendance/edit/:id",
    pathString: (id) => {
      return `/attendance/edit/${id}`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "attendance"
    },
    component: React.lazy(() =>
      import("../pages/Attendance/EditAttendance/EditAttendance")
    ),
  },
  volunteerOverview: {
    id: "volunteerOverview",
    name: "Volunteer Overview",
    description: "Volunteer Overview",
    path: "/volunteering",
    pathString: () => {
      return "/volunteering";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      permissionKey: "volunteering"
    },
    component: React.lazy(() => import("../pages/Volunteering/Volunteering")),
  },
  menteeOverview: {
    id: "menteeOverview",
    name: "Mentee Overview",
    description: "Mentee Overview",
    path: "/mentees",
    pathString: () => {
      return "/mentees";
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
    },
    component: React.lazy(() =>
      import("../pages/Mentees/MenteeOverview/MenteeOverview")
    ),
  },
  menteeApplication: {
    id: "MenteeApplication",
    name: "Mentee Application",
    description: "Mentee Application",
    path: "/mentees/application/:id",
    pathString: (id) => {
      return `/mentees/application/${id}`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
    },
    component: React.lazy(() =>
      import("../pages/Mentees/MenteeApplication/MenteeApplication")
    ),
  },
  performanceTracking: {
    id: "performanceTracking",
    name: "Performance Tracking",
    description: "Performance Tracking",
    path: "/mentees/performance-tracking/:id",
    pathString: (id) => {
      return `/mentees/performance-tracking/${id}`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true
    },
    component: React.lazy(() =>
      import("../pages/Mentees/PerformanceTracking/PerformanceTracking")
    ),
  },
  newPerformanceTracking: {
    id: "newPerformanceTracking",
    name: "New Performance Tracking",
    description: "New Performance Tracking",
    path: "/mentees/track-performance/:id",
    pathString: (id) => {
      return `/mentees/track-performance/${id}`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true
    },
    component: React.lazy(() =>
      import("../pages/Mentees/PerformanceTracking/NewPerformanceTracking")
    ),
  },
  userManagement: {
    id: "userManagement",
    name: "User Management",
    description: "User Management",
    path: "/admin/users",
    pathString: () => {
      return `/admin/users`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      isAdminRoute: true
    },
    component: React.lazy(() =>
      import("../pages/Admin/UserManagement/UserManagement")
    ),
  },
  menteeAppManagement: {
    id: "userManagement",
    name: "User Management",
    description: "User Management",
    path: "/mentee-applications",
    pathString: () => {
      return `/mentee-applications`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
    },
    component: React.lazy(() =>
      import("../pages/MenteeAppManagment/MenteeAppManagment")
    ),
  },
  permissions: {
    id: "permissions",
    name: "Permissions",
    description: "Permissions",
    path: "/admin/permissions",
    pathString: () => {
      return `/admin/permissions`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      isAdminRoute: true
    },
    component: React.lazy(() =>
      import("../pages/Admin/Permissions/Permissions")
    ),
  },
  menteeApplicationAdmin: {
    id: "menteeApplicationAdmin",
    name: "Mentee Application",
    description: "Mentee Application",
    path: "/mentee-applications/view/:id",
    pathString: (id) => {
      return `/mentee-applications/view/${id}`;
    },
    exact: true,
    metaProps: {
      isPrivate: true,
      isAuth: false,
      onlyApprovedUser: true,
      noHeader: true
    },
    component: React.lazy(() =>
      import("../pages/Admin/MenteeApplication/MenteeApplication")
    ),
  },
};
