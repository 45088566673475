import { XMarkIcon } from "@heroicons/react/24/solid"
import classNames from "classnames"
import { useLayoutEffect, useState } from "react"
import { createPortal } from "react-dom"
import "./Drawer.css"

const Portal = ({ children, wrapperId, className }) => {
  const [wrapperEle, setWrapperEle] = useState(null)

  useLayoutEffect(() => {
    let ele = document.getElementById(wrapperId)
    let systemCreated = false

    // create wrapper if not found
    if (!ele) {
      systemCreated = true
      const wrapEle = document.createElement('div')
      wrapEle.setAttribute('id', wrapperId)
      wrapEle.setAttribute('class', className)
      document.body.appendChild(wrapEle)
      ele = wrapEle
    }

    setWrapperEle(ele)

    return () => {
      if (systemCreated && ele.parentNode) {
        ele.parentNode.removeChild(ele)
      }
    }
  }, [wrapperId])

  if (!wrapperEle) return null

  return createPortal(children, wrapperEle)
}


const Drawer = ({ visible, onClose, title, children }) => {

  useLayoutEffect(() => {
    if (visible) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = null;
    }
  }, [visible])
//   if (!visible) return null
  return (
    <Portal wrapperId="drawer-area" className="overflow-hidden relative">
      {visible && (
        <div
          className="z-[1000] transition duration-300 fixed top-0 right-0 bottom-0 left-0 bg-black inset-0 bg-opacity-50"
          onClick={onClose}
        ></div>
      )}
      <div
        id="drawer"
        className={
            classNames("overflow-scroll w-full md:w-80 bg-white h-screen fixed top-0 right-0 bottom-0 z-[1100] transition-transform duration-300", {
              "translate-x-full": !visible
            })
        }
      >
        <div className="bg-secondary sticky top-0 text-white flex items-center justify-between p-4">
            <h5 className="font-semibold text-[17px]">{title}</h5>
            <button onClick={onClose} className='rounded-full hover:bg-opacity-25 bg-opacity-0 bg-white p-[7px]'><XMarkIcon className="w-5 h-5"/></button>
        </div>
        {children}
      </div>
    </Portal>
  );
}

export default Drawer