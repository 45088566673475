import { Volunteer } from 'store/types'

const initialState = {
  events: {
    list: [],
    count: 0,
    page: 0
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case Volunteer.SET_VOLUNTEER_EVENTS: {
      const { count, list, page, reset } = action.payload
      return {
        ...state,
        events: {
          count,
          page,
          list:
            state.events.list.length && !reset
              ? [...state.events.list, ...list]
              : list,
        }
      }
    }
    case Volunteer.ADD_VOLUNTEER_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          list: [action.payload, ...state.events.list],
          count: state.events.count + 1,
        },
      };
    }
    case Volunteer.UPDATE_VOLUNTEER_EVENT: {
      const { id, data } = action.payload
      return {
        ...state,
        events: {
          ...state.events,
          list: state.events.list.map(event => {
            if (event.id === id) {
              return {
                ...event,
                ...data
              }
            }
            return event;
          })
        }
      }
    }
    case Volunteer.REMOVE_VOLUNTEER_EVENT: {
      const { id } = action.payload
      return {
        ...state,
        events: {
          ...state.events,
          count: state.events.count - 1,
          list: state.events.list.filter((item) => item.id !== id),
        },
      };
    }
    case Volunteer.RESET_VOLUNTEER_EVENTS: {
      return {
        ...state,
        events: initialState.events,
      };
    }
    default:
      return state
  }
}

export default reducer
