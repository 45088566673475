const { Mentee } = require("store/types")

const initialState = {
  mentees: {
    list: [],
    count: 0,
    page: 0,
  },
  reportCards: {
    list: [],
    count: 0,
    page: 0,
  },
  behaviorSurveys: {
    list: [],
    count: 0,
    page: 0,
  },
  hsCredits: {
    list: [],
    count: 0,
    page: 0,
  },
  newPerfData: {},
  selectedMentee: null,
  menteeApplication: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Mentee.SET_MENTEES: {
      const { list, count, page, reset } = action.payload;
      return {
        ...state,
        mentees: {
          count,
          page,
          list:
            state.mentees.list.length && !reset
              ? [...state.mentees.list, ...list]
              : list,
        },
      };
    }
    case Mentee.SET_SELECTED_MENTEE: {
      return {
        ...state,
        selectedMentee: action.payload
      }
    }
    case Mentee.SET_SELECTED_MENTEE_APP: {
      return {
        ...state,
        menteeApplication: action.payload
      }
    }
    case Mentee.SET_NEW_PERF_DATA: {
      if (!action.payload) {
        return {
          ...state,
          newPerfData: {}
        }
      }
      return {
        ...state,
        newPerfData: {
          ...state.newPerfData,
          ...action.payload
        }
      }
    }
    case Mentee.SET_REPORT_CARDS: {
      const { count, list, page, reset } = action.payload
      return {
        ...state,
        reportCards: {
          count,
          page,
          list:
            state.reportCards.list.length && !reset
              ? [...state.reportCards.list, ...list]
              : list,
        }
      }
    }
    case Mentee.SET_BEHAVIOR_SURVEYS: {
      const { count, list, page, reset } = action.payload
      return {
        ...state,
        behaviorSurveys: {
          count,
          page,
          list:
            state.behaviorSurveys.list.length && !reset
              ? [...state.behaviorSurveys.list, ...list]
              : list,
        },
      };
    }
    case Mentee.SET_HS_CREDITS: {
      const { count, list, page, reset } = action.payload
      return {
        ...state,
        hsCredits: {
          count,
          page,
          list:
            state.hsCredits.list.length && !reset
              ? [...state.hsCredits.list, ...list]
              : list,
        },
      };
    }
    case Mentee.RESET_PERF_DATA: {
      const { hsCredits, reportCards, behaviorSurveys } = initialState
      return {
        ...state,
        reportCards,
        behaviorSurveys,
        hsCredits
      }
    }
    case Mentee.UPDATE_REPORT_CARD: {
      const { id, ...otherData } = action.payload
      return {
        ...state,
        reportCards: {
          ...state.reportCards,
          list: state.reportCards.list.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                ...otherData
              }
            }
            return row
          })
        }
      }
    }
    case Mentee.UPDATE_BEHAVIOR_SURVEY: {
      const { id, ...otherData } = action.payload
      return {
        ...state,
        behaviorSurveys: {
          ...state.behaviorSurveys,
          list: state.behaviorSurveys.list.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                ...otherData
              }
            }
            return row
          })
        }
      }
    }
    case Mentee.UPDATE_HS_CREDIT: {
      const { id, ...otherData } = action.payload
      return {
        ...state,
        hsCredits: {
          ...state.hsCredits,
          list: state.hsCredits.list.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                ...otherData
              }
            }
            return row
          })
        }
      }
    }
    case Mentee.REMOVE_REPORT_CARD: {
      const { id } = action.payload
      return {
        ...state,
        reportCards: {
          ...state.reportCards,
          list: state.reportCards.list.filter(row => row.id !== id)
        }
      }
    }
    case Mentee.REMOVE_BEHAVIOR_SURVEY: {
      const { id } = action.payload
      return {
        ...state,
        behaviorSurveys: {
          ...state.behaviorSurveys,
          list: state.behaviorSurveys.list.filter(row => row.id !== id)
        }
      }
    }
    case Mentee.REMOVE_HS_CREDIT: {
      const { id } = action.payload
      return {
        ...state,
        hsCredits: {
          ...state.hsCredits,
          list: state.hsCredits.list.filter(row => row.id !== id)
        }
      }
    }
    default:
      return state;
  }
};

export default reducer
