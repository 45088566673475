import {
  CalendarDaysIcon,
  HomeIcon,
  UserCircleIcon,
  ClipboardDocumentCheckIcon,
  HandRaisedIcon,
  UserGroupIcon,
  LockClosedIcon,
  Cog6ToothIcon,
  UserIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import Drawer from "components/Drawer/Drawer";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routes } from "router/routes";

const adminMenuList = [
  {
    name: "Users",
    path: routes.userManagement.path,
    icon: UserGroupIcon,
  },
  {
    name: "Permissions",
    path: routes.permissions.path,
    icon: LockClosedIcon,
  },
  // {
  //   name: "Site Settings",
  //   path: routes.attendanceOverview.path,
  //   icon: Cog6ToothIcon,
  // },
];

const MenuDrawer = ({ visible, onClose, title, children, user }) => {

  const menuList = [
    {
      name: "Home",
      path: routes.home.path,
      permissionKey: 'home',
      icon: HomeIcon,
    },
    {
      name: "Directory",
      path: routes.directory.path,
      permissionKey: 'directory',
      icon: UserCircleIcon,
    },
    {
      name: "Events",
      path: routes.events.path,
      permissionKey: 'events',
      icon: CalendarDaysIcon,
    },
    {
      icon: UserGroupIcon,
      path: routes.menteeOverview.path,
      name: "Manage Mentees",
      permissionKey: 'home',
      hidden: user.role.userType !== 'parent'
    },
    {
      name: "Attendance",
      path: routes.attendanceOverview.path,
      permissionKey: 'attendance',
      icon: ClipboardDocumentCheckIcon,
    },
    {
      name: "Volunteering",
      path: routes.volunteerOverview.path,
      permissionKey: 'volunteering',
      icon: HandRaisedIcon,
    },
    {
      icon: DocumentTextIcon,
      path: routes.fileManager.path,
      permissionKey: 'fileManager',
      name: "Files",
    },
    {
      name: "Mentee Applications",
      path: routes.menteeAppManagement.path,
      permissionKey: 'menteeApplication',
      icon: UserIcon,
    },
  ];
  
  return (
    <Drawer title="Menu" visible={visible} onClose={onClose}>
      <div>
        <ul>
          {menuList
            .filter((menu) => {
              if (menu.permissionKey === "home") return true;
              return ["read", "full"].includes(
                user?.role?.permissions?.[menu.permissionKey] || ""
              );
            })
            .map((menu, index) => {
              if (menu.hidden) return null;
              return (
                <li key={index}>
                  <NavLink
                    to={menu.path}
                    onClick={onClose}
                    className={({ isActive }) => {
                      return classNames(
                        "flex items-center px-4 border-l-[4px] py-4 hover:bg-gray-200 cursor-pointer",
                        {
                          "border-l-secondary text-secondary": isActive,
                          "border-transparent": !isActive,
                        }
                      );
                    }}
                  >
                    <menu.icon className="w-6 h-6 mr-[20px]" />
                    <span className="font-semibold text-[16px]">
                      {menu.name}
                    </span>
                  </NavLink>
                </li>
              );
            })}
        </ul>
        {user?.role?.permissions?.admin === "full" ? (
          <>
            <p className="px-4 border-l-[4px] border-transparent py-4 text-gray-600 text-sm">
              Admin Menu
            </p>
            <ul>
              {adminMenuList.map((menu, index) => (
                <li key={index}>
                  <NavLink
                    to={menu.path}
                    onClick={onClose}
                    className={({ isActive }) => {
                      return classNames(
                        "flex items-center px-4 border-l-[4px] py-4 hover:bg-gray-200 cursor-pointer",
                        {
                          "border-l-secondary text-secondary": isActive,
                          "border-transparent": !isActive,
                        }
                      );
                    }}
                  >
                    <menu.icon className="w-6 h-6 mr-[20px]" />
                    <span className="font-semibold text-[16px]">
                      {menu.name}
                    </span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </Drawer>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})

export default connect(mapStateToProps)(MenuDrawer);
