import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { routes } from "./routes";

const PrivateRoute = ({ children, metaProps, user }) => {
  const location = useLocation()
  if (!user) {
    return (
      <Navigate to={`${routes.login.path}?from=${location.pathname}`}/>
    )
  }
  if (!user.agreedToRules && !metaProps.isWelcome) {
    let path = routes.welcomePage.path
    if (location.pathname !== '/') {
      path = `${routes.welcomePage.path}?from=${location.pathname}`;
    }
    return (
      <Navigate to={path}/>
    )
  }
  if (user && !user.approveduser && metaProps.onlyApprovedUser) {
    return (
      <Navigate to={routes.restrictedPage.path}/>
    )
  }
  if (user && metaProps.permissionKey && !['read', 'full'].includes(user?.role?.permissions[metaProps.permissionKey] || '')) {
    return (
      <Navigate to='/404'/>
    )
  }
  if (user && metaProps.isAdminRoute && user?.role?.permissions?.admin !== 'full') {
    return <Navigate to="/404" />;
  }
  return <div>{children}</div>;
};

export default connect(({ auth }) => ({ user: auth.user }))(PrivateRoute);
