import { combineReducers } from 'redux'
import auth from './auth'
import post from './post'
import event from './event'
import attendance from "./attendance";
import directory from "./directory"
import volunteer from "./volunteer"
import mentee from "./mentee"
import admin from "./admin"
import fileManager from "./fileManager"
import notification from "./notification"

export const rootReducer = combineReducers({
  auth,
  directory,
  post,
  event,
  attendance,
  volunteer,
  mentee,
  admin,
  notification,
  fileManager
})
