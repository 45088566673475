import { initializeApp } from 'firebase/app'
import { getAuth } from  "firebase/auth"
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore'
// import "firebase/messaging";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_SENDER_ID,
});

// let _messaging = null;
// if (firebase.messaging.isSupported()) {
//   _messaging = firebase.messaging();
// }

export const firebaseAuth = getAuth(app)
export const firestoreDb = getFirestore(app)
export const storage = getStorage(app)
export const fireBase = app
// export const messaging = _messaging
