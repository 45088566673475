import { useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router";
import {
  BellIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  Bars3Icon,
} from "@heroicons/react/24/solid";
import Logo from "assets/img/logo.png";
import Mentor100Logo from "assets/img/m100.jpg"
import Avatar from "assets/img/avatar.png";
import Button from "components/Button/Button";
import Dropdown from "components/Dropdown/Dropdown";
import { logout } from "store/actions/auth";
import { routes } from "router/routes";
import { NavLink } from "react-router-dom";
import MenuDrawer from "components/MenuDrawer/MenuDrawer";

const menuActions = [
  {
    key: 'send-feedback',
    href: 'mailto:mentor100.support@thesimplevue.com',
    name: 'Send Feedback'
  },
  // {
  //   key: 'faq',
  //   name: 'FAQ'
  // },
  {
    key: 'signout',
    name: 'Sign Out'
  }
]

const Header = ({ user, logout }) => {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false)

  const handleMenuAction = (action) => {
    switch(action.key) {
      case 'signout': {
        return logout()
      }
      case 'send-feedback': {
        return window.location.href = action.href
      }
      default:
        return
    }
  }

  return (
    <nav className="border-b bg-white border-b-secondary shadow-sm sticky top-0 z-[1000]">
      <div className="container mx-auto px-[10px] md:px-[20px] flex justify-between">
        <div className="flex items-center py-[10px]">
          <NavLink to={routes.home.path} className="flex">
            <div className="flex border-r border-r-gray-300 pr-[10px]">
              <img src={Logo} alt="Site Logo" className="h-[45px]" />
            </div>
            <div className="pl-[10px]">
              <img
                src={Mentor100Logo}
                alt="Mentor 100 Logo"
                className="h-[45px]"
              />
            </div>
          </NavLink>
          {/* <div
            className={classNames({
              "hidden ml-[30px] sm:flex items-center w-[279px] border-b-2 border-secondary px-[15px] py-[10px] transition-[width] duration-300 ease-in-out": true,
              "!w-[360px]": searchIsOpen,
            })}
          >
            <MagnifyingGlassIcon className="w-[20px] h-[20px] mr-2 text-secondary" />
            <input
              className={classNames({
                "focus:outline-none border-none p-0 w-[221px] placeholder-secondary focus:ring-0 shadow-none ease-in-out transition-[width] duration-300": true,
                "w-[300px]": searchIsOpen,
              })}
              onClick={() => setSearchIsOpen((v) => !v)}
              onBlur={() => setSearchIsOpen((v) => !v)}
              type="search"
              autoComplete="off"
              placeholder="Search"
            />
          </div> */}
        </div>
        <div>
          <div className="flex items-center h-full">
            {/* <Button className="!shadow-none sm:hidden border-none ml-[20px] w-[60px] flex items-center justify-center hover:bg-red-200 !p-0 h-full">
              <MagnifyingGlassIcon className="w-[25px] h-[25px] text-secondary" />
            </Button> */}
            <div className="navigation mr-[10px] h-full">
              {/* <li className="float-left w-[60px] cursor-pointer h-full flex items-center justify-center hover:bg-gray-200 transition-all duration-[0.3s]">
                <BellIcon className="w-[30px] h-[30px] text-secondary" />
              </li> */}
              <a
                href="mailto:mentor100.support@thesimplevue.com"
                // target="_blank"
                // rel="noreferrer"
              >
                <div className="float-left w-[60px] cursor-pointer h-full flex items-center justify-center hover:bg-gray-200 transition-all duration-[0.3s]">
                  <QuestionMarkCircleIcon className="w-[30px] h-[30px] text-red-400" />
                </div>
              </a>
            </div>
            <Dropdown
              className="!w-[300px] pb-[10px] !ring-0 shadow-lg top-[50px]"
              menuItems={
                <>
                  <Menu.Item>
                    <div className="px-4 pt-4">
                      <div className="flex items-center">
                        <div className="mr-3">
                          <div
                            className="bg-cover border-2 border-secondary w-[40px] h-[40px] rounded-full cursor-pointer"
                            style={{
                              backgroundImage: `url(${user?.avatar || Avatar})`,
                            }}
                          />
                        </div>
                        <div className="">
                          <b>{`${user.firstName} ${user.lastName}`}</b>
                          <p className="text-sm text-gray-500 text-ellipsis overflow-hidden w-[214px]">
                            {user.email}
                          </p>
                        </div>
                      </div>
                      <Button
                        as={NavLink}
                        to={routes.myProfile.path}
                        className="w-full block text-center !shadow-none font-bold text-sm mt-3 !py-[8px] hover:bg-secondary hover:text-white hover:!border-secondary"
                      >
                        Profile
                      </Button>
                      <hr className="mt-4" />
                    </div>
                  </Menu.Item>
                  {menuActions.map((item, index) => (
                    <Menu.Item key={index}>
                      <div
                        className="hover:text-secondary first:rounded-t-md last:rounded-b-md px-4 py-2 text-sm cursor-pointer flex gap-2 items-center  transition duration-200"
                        onClick={() => handleMenuAction(item)}
                      >
                        <span>{item.name}</span>
                      </div>
                    </Menu.Item>
                  ))}
                </>
              }
            >
              <button className="border-r border-gray-300 h-full flex items-center justify-center pr-[10px]">
                <div
                  className="bg-cover border-2 border-secondary w-[40px] h-[40px] rounded-full cursor-pointer"
                  style={{
                    backgroundImage: `url(${user?.avatar || Avatar})`,
                  }}
                />
              </button>
            </Dropdown>
            <div className="pl-[10px] flex items-center justify-center cursor-pointer">
              <div
                className="text-primary"
                onClick={() => setDrawerVisible(true)}
              >
                <Bars3Icon className="w-[30px] h-[30px] mx-auto" />
                <span className="text-sm leading-tight block font-semibold">
                  Menu
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MenuDrawer
        title="Menu"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      />
    </nav>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
  };
};

export default connect(mapStateToProps, {
  logout
})(Header);
